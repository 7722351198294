export const NAV_HEIGHT = 56

export const colorArray = ['blue', 'green', 'purple', 'pink']

// TODO: en is only used for image name so remove it later
export const tagImageNames = {
  web_service: {en: 'web-service', jp: 'Webサービス開発', color: 'purple'},
  smartphone: {en: 'smartphone', jp: 'スマホアプリ開発', color: 'blue'},
  data_ml: {en: 'ai', jp: 'データ分析・機械学習・IoT', color: 'orange'},
  education: {en: 'education', jp: '教育関連サービス', color: 'green'},
  ops_maintenance: {en: 'support', jp: 'インフラ・運用保守', color: 'pink'}
}

export const internalLinks = [
  {title: '企業理念', path: '/philosophy/'},
  {title: '事業内容', path: '/services/'},
  {title: '実績紹介', path: '/works/'},
  {title: 'メンバー', path: '/members/'},
  {title: '採用情報', path: '/recruit/'},
  {title: '会社概要', path: '/about/'},
  {title: '資料請求', path: '/download/'},
]

export const strengthList = [
  {
    title: '高品質な開発',
    body: '技術力の高い開発者、経験豊富なプロジェクトマネージャーが、高品質なソフトウェアを開発します。',
    key: 'develop'
  },
  {
    title: '適正価格なシステム',
    body: '無駄（多重下請け、不要不急な機能の開発等）の排除、開発作業の効率化により、費用対効果の高いシステム開発を行います。',
    key: 'price'
  },
  {
    title: '柔軟な対応',
    body: '高い技術力、余裕をもった人員計画により、お客様の急なご要望にも可能な限り柔軟にお応えします。',
    key: 'flexible'
  }
]
